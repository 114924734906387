export const generatePaginationArray = (
    numberOfPages: number,
    currentPage: number,
) => {
    const maxVisiblePages = 7;
    const items = [];

    // Always show the first page
    items.push({ type: "pageNumber", number: 1 });

    if (numberOfPages <= maxVisiblePages) {
        // If there are fewer or equal to 7 pages, display all pages as page numbers
        for (let i = 2; i <= numberOfPages - 1; i++) {
            items.push({ type: "pageNumber", number: i });
        }
    } else {
        const leftOffset = 1; // Number of pages to the left of the current page
        const rightOffset = 1; // Number of pages to the right of the current page

        // Determine the range of pages to display as page numbers
        let startPage = currentPage - leftOffset;
        let endPage = currentPage + rightOffset;

        // Ensure the range is within bounds
        if (startPage < 4) {
            startPage = 2;
            endPage = maxVisiblePages - 2;
        } else if (endPage > numberOfPages - 3) {
            startPage = numberOfPages - maxVisiblePages + 3;
            endPage = numberOfPages - 1;
        }

        // Add three dots if necessary
        if (startPage >= 4) {
            items.push({ type: "threeDots", number: undefined });
        }

        for (let i = startPage; i <= endPage; i++) {
            items.push({ type: "pageNumber", number: i });
        }

        if (endPage < numberOfPages - 1) {
            items.push({ type: "threeDots", number: undefined });
        }
    }

    // Always show the last page
    items.push({ type: "pageNumber", number: numberOfPages });

    return items;
};